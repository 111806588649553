import { render, staticRenderFns } from "./classData_stuUse.vue?vue&type=template&id=92219776&scoped=true"
import script from "./classData_stuUse.vue?vue&type=script&lang=js"
export * from "./classData_stuUse.vue?vue&type=script&lang=js"
import style0 from "./classData_stuUse.vue?vue&type=style&index=0&id=92219776&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92219776",
  null
  
)

export default component.exports